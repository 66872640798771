import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import AffineTransformationCommandRepositoryInterface from '@/services/MapEditor/domain/repository/AffineTransformationCommandRepositoryInterface'
import AffineTransformationCreateCommand from '@/services/MapEditor/application/command/AffineTransformationCreateCommand'
import { AffineTransformationDTO } from '@/services/MapEditor/domain/model/Geometry/AffineTransformation'

export default class AffineTransformationCreateHandler implements CommandHandlerInterface
{
    private repo: AffineTransformationCommandRepositoryInterface;

    constructor(repo: AffineTransformationCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: AffineTransformationCreateCommand): Promise<void>
    {
        let item: AffineTransformationDTO = {
            name: command.getName(),
            description: command.getDescription(),
            x0: command.getX0(),
            y0: command.getY0(),
            z0: command.getZ0(),
            a: command.getA(),
            b: command.getB(),
            c: command.getC(),
            d: command.getD(),
            e: command.getE(),
            f: command.getF(),
            g: command.getG(),
            h: command.getH(),
            i: command.getI(),
            project_id: command.getProjectId()
        };
        return this.repo.insert(item);
    }
}